<template>
  <div class="referee-mobile">
    <div
      class="columns is-multiline is-mobile"
      style="padding: 0 0.5em 0.5em 0.5em"
    >
      <div class="column is-three-fifths" style="padding: 0 0.75em">
        <div style="text-align: left">
          <p class="name-ref">{{ referee.arbitro }}</p>
        </div>
      </div>
      <div class="column is-narrow">
        <div
          v-if="referee.partida"
          class="level is-mobile"
          style="padding-top: 0 !important;"
        >
          <div class="level-item">
            <div class="emb-team centralize">
              <div>
                <img :src="referee.escudo_clube_casa" width="25" alt="" />
              </div>
            </div>
          </div>
          <div class="level-item">
            <b>X</b>
          </div>
          <div class="level-item">
            <div class="emb-team centralize">
              <div>
                <img :src="referee.escudo_clube_fora" width="25" alt="" />
              </div>
            </div>
          </div>
        </div>
        <span v-else style="opacity: 0.2">
          Sem Partida
        </span>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>Jogos</b>
          <p>{{ referee.qtd_jogos }}</p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>FC / Jogo</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.FC_jogo_total.toFixed(1).replace(".", ",")
                : param.clubes == "casa"
                ? referee.FC_jogo_casa.toFixed(1).replace(".", ",")
                : referee.FC_jogo_fora.toFixed(1).replace(".", ",")
            }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>FC / DS</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.FC_DS_total.toFixed(1).replace(".", ",")
                : param.clubes == "casa"
                ? referee.FC_DS_casa.toFixed(1).replace(".", ",")
                : referee.FC_DS_fora.toFixed(1).replace(".", ",")
            }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>Pn. / Jogo</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.PT_jogo_total.toFixed(1).replace(".", ",")
                : param.clubes == "casa"
                ? referee.PT_jogo_casa.toFixed(1).replace(".", ",")
                : referee.PT_jogo_fora.toFixed(1).replace(".", ",")
            }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CA / Jogo</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.CA_jogo_total.toFixed(1).replace(".", ",")
                : param.clubes == "casa"
                ? referee.CA_jogo_casa.toFixed(1).replace(".", ",")
                : referee.CA_jogo_fora.toFixed(1).replace(".", ",")
            }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CA</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.CA_total
                : param.clubes == "casa"
                ? referee.CA_casa
                : referee.CA_fora
            }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CV / Jogo</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.CV_jogo_total.toFixed(1).replace(".", ",")
                : param.clubes == "casa"
                ? referee.CV_jogo_casa.toFixed(1).replace(".", ",")
                : referee.CV_jogo_fora.toFixed(1).replace(".", ",")
            }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CV</b>
          <p>
            {{
              param.clubes == "total"
                ? referee.CV_total
                : param.clubes == "casa"
                ? referee.CV_casa
                : referee.CV_fora
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefereeColumn",
  props: {
    referee: {
      type: Object,
      required: true,
    },
    param: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.name-ref {
  font-size: 1.2em !important;
  font-weight: bold;
}
.referee-mobile {
  font-size: 0.8em;
  text-align: center;
}
</style>
