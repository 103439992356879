<template>
  <div class="referee-mobile">
    <div
      class="columns is-multiline is-mobile"
      style="padding: 0 0.5em 0.5em 0.5em"
    >
      <div class="column is-three-fifths" style="padding: 0 0.75em">
        <div style="width: max-content; text-align: left;">
          <div style="display: inline-table; vertical-align: middle;">
            <img :src="club.escudo_clube" width="30" alt="" />
          </div>
          <div
            style="display: inline-table; vertical-align: middle; margin-left: 5px;"
          >
            {{ club.nm_clube }}
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div
          v-if="club.partida"
          class="level is-mobile"
          style="padding-top: 0 !important;"
        >
          <div class="level-item">
            <div class="emb-team centralize">
              <div>
                <img :src="club.escudo_clube_casa" width="25" alt="" />
              </div>
            </div>
          </div>
          <div class="level-item">
            <b>X</b>
          </div>
          <div class="level-item">
            <div class="emb-team centralize">
              <div>
                <img :src="club.escudo_clube_fora" width="25" alt="" />
              </div>
            </div>
          </div>
        </div>
        <span v-else style="opacity: 0.2">
          Sem Partida
        </span>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>Jogos</b>
          <p>{{ club.qtd_jogos_total_clube }}</p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>FC / Jogo</b>
          <p>
            {{ club.FC_jogo_total_clube.toFixed(1).replace(".", ",") }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>FC / DS</b>
          <p>
            {{ club.FC_DS_total_clube.toFixed(1).replace(".", ",") }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>Pn. / Jogo</b>
          <p>
            {{ club.PT_jogo_total_clube.toFixed(1).replace(".", ",") }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CA / Jogo</b>
          <p>
            {{ club.CA_jogo_total_clube.toFixed(1).replace(".", ",") }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CA</b>
          <p>
            {{ club.CA_total_clube }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CV / Jogo</b>
          <p>
            {{ club.CV_jogo_total_clube.toFixed(1).replace(".", ",") }}
          </p>
        </div>
      </div>
      <div class="column is-one-quarter" style="padding: 0.25em">
        <div>
          <b>CV</b>
          <p>
            {{ club.CV_total_clube }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClubColumn",
  props: {
    club: {
      type: Object,
      required: true,
    },
    param: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.name-ref {
  font-size: 1.2em !important;
  font-weight: bold;
}
.referee-mobile {
  font-size: 0.8em;
  text-align: center;
}
</style>
